<template>
  <ul>
    <li v-for="task in tasks" :key="task.id"
      class="flex justify-between items-center px-4 py-2 text-xs bg-black text-white rounded mb-3">
      <span>
        {{ task.title }}
      </span>
      <div class="relative task-options">
        <button @click="removeTask(task.id)" class="absolute w-full h-full bg-black text-white">
          X
        </button>
        <span class="hours font-bold">
          {{ task.hours }}
        </span>
      </div>
    </li>
  </ul>
  <div v-if="totalHours !== 0">
    Totaal: {{ totalHours }}
  </div>
  <div v-if="showAddTaskForm" class="mt-6">
    <input v-model="title" placeholder="Beschrijving" type="text" class="mb-2">
    <input v-model="hours" placeholder="Uren" type="number" class="mb-2">
    <div class="flex items-center -mx-2">
      <button @click="showAddTaskForm = false" class="w-full px-6 py-2 text-sm font-semibold border rounded mx-2">
        Annuleren
      </button>
      <button @click="createTask" class="w-full px-6 py-2 text-sm font-semibold bg-black text-white rounded mx-2">
        Opslaan
      </button>
    </div>
  </div>
  <div v-if="!showAddTaskForm" class="flex justify-center mt-4">
    <button @click="addTask(date)"
      class="flex justify-center items-center w-8 h-8 rounded-full bg-gray-400 text-white text-2xl font-bold hover:bg-black transition">
      +
    </button>
  </div>
</template>

<script>

import db from '@/firebase'
import dayjs from 'dayjs'
// import { collection, addDoc, setDoc, doc, deleteDoc } from 'firebase/firestore'
import { collection, addDoc, deleteDoc, doc } from 'firebase/firestore'

export default {
  props: {
    date: {
      type: String,
      default: ''
    },
    tasks: {
      type: Array,
      default: () => []
    },
    userId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showAddTaskForm: false,
      title: '',
      hours: 0
    }
  },
  computed: {
    totalHours () {
      return this.tasks.map(task => task.hours).reduce((partialSum, a) => partialSum + a, 0)
    }
  },
  methods: {
    addTask (date) {
      this.showAddTaskForm = true
      console.log(date)
    },
    async createTask () {
      this.showAddTaskForm = false
      await addDoc(collection(db, 'Task'), {
        title: this.title,
        hours: this.hours,
        date: this.date,
        weekNumber: dayjs(this.date).week(),
        user_id: this.userId
      })
      this.title = ''
      this.hours = 0
    },
    async removeTask (id) {
      await deleteDoc(doc(db, 'Task', id))
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">

.task-options {
  button {
    display: none;
  }

  &:hover {
    button {
      display: block;
    }
  }
}
</style>
