<template>
  <div class="hour-logging">
    <site-header />
     <ul class="flex items-center justify-center">
      <li v-for="user in users" :key="user.name" class="mx-4">
        <button
          class="relative w-14 h-14 rounded-full"
          :class="{ 'active-user': selectedUser === user.id }"
          @click="selectUser(user.id)"
        >
          <img :src="require(`@/assets/img/${user.image}`)" class="absolute left-0 top-0 w-full h-full object-cover rounded-full">
        </button>
      </li>
    </ul>
    <div class="p-6">
      <header class="flex items-center justify-center mb-6">
        <button @click="updateCurrentWeek(-1)" class="transform rotate-180">
          <icon name="arrow-right" class="text-gray-400 text-xl" />
        </button>
        <span class="text-2xl font-medium mx-4">
          {{ this.weekStart }} - {{ this.weekEnd }}
        </span>
        <button @click="updateCurrentWeek(1)" class="">
          <icon name="arrow-right" class="text-gray-400 text-xl" />
        </button>
      </header>
      <div class="content-container bg-white rounded-xl">
        <ul class="dates-container flex whitespace-nowrap w-full">
          <li v-for="day in weekDays" :key="day.date" class="dates py-6">
            <button @click="selectDay(day.date)" class="w-full h-24">
              <span class="text-gray-400 font-medium mb-1">
                {{ day.dayName }}
              </span>
              <span class="text-3xl font-bold">
                {{ day.day }}
              </span>
            </button>
          </li>
        </ul>
      </div>
      <ul class="flex">
        <li
          v-for="(day, i) in currentUserTasks" :key="`day-${day.date}-${i}`"
          class="item border-t p-3 dates border-r"
          :class="{ 'is-weekend': i > 4 }"
        >
          <single-day-tasks
            :date="day.date"
            :tasks="day.tasks"
            :user-id="selectedUser"
          />
        </li>
      </ul>
    </div>
    <!-- <ul>
      <li v-for="(task, i) in currentTasks" :key="`${task.date}-${i}-${task.user_id}`">
        {{ task.title }}
      </li>
    </ul> -->
  </div>
</template>

<script>

import db from '@/firebase'
import { collection, query, where, onSnapshot } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import dayjs from 'dayjs'
import SiteHeader from '@/components/SiteHeader.vue'
import SingleDayTasks from '../components/SingleDayTasks.vue'

const weekday = require('dayjs/plugin/weekday')
const weekOfYear = require('dayjs/plugin/weekOfYear')
require('dayjs/locale/nl')
dayjs.locale('nl')
dayjs.extend(weekday)
dayjs.extend(weekOfYear)
dayjs.Ls.en.weekStart = 1

let unsubscribe = null

export default {
  name: 'Home',
  components: {
    SiteHeader,
    SingleDayTasks
  },
  data () {
    return {
      currentIndex: 0,
      weekDays: [],
      weekStart: '',
      weekEnd: '',
      selectedDate: '',
      selectedUser: 'WPALlrVt4iVcVJXwDsvNdxWTSLB3',
      users: [
        {
          id: 'WPALlrVt4iVcVJXwDsvNdxWTSLB3',
          name: 'Erwin',
          image: 'erwin.jpg'
        },
        {
          id: 'p3FCtWK3rhPlMJQyOhyGgYHt8KR2',
          name: 'Samuela',
          image: 'samuela.jpg'
        },
        {
          id: 'P76XK51XhZMhA7DqvJGofFsnDJW2',
          name: 'GJ',
          image: 'gj.jpg'
        },
        {
          id: 'FMH6dxi83xQMkCfc8TyNyVJawE83',
          name: 'Daphne',
          image: 'daphne.jpg'
        },
        {
          id: 'j2Ol6zwMuyfqSvqBeZzxLu3wlDO2',
          name: 'Lydia',
          image: 'lydia.jpg'
        },
        {
          id: 'Uh7T78gbATS0ZZ16O4VuEdnbBRt2',
          name: 'Denise',
          image: 'denise.jpg'
        }
      ]
    }
  },
  mounted () {
    const auth = getAuth()
    const currentUser = this.users.find(user => user.id === auth.currentUser.uid)
    if (currentUser) {
      this.selectedUser = currentUser.id
    }
    this.getData()
    this.weekDays = this.getWeekDays(this.currentIndex)
  },
  computed: {
    selectedWeekNumber () {
      const currentWeekStart = dayjs().startOf('week').format('YYYY-MM-DD')
      return dayjs(currentWeekStart).add(this.currentIndex, 'week').week()
    },
    currentUserTasks () {
      return this.weekDays.map(day => {
        return {
          date: day.date,
          tasks: day.tasks.filter(task => task.user_id === this.selectedUser)
        }
      })
    }
  },
  methods: {
    updateCurrentWeek (direction) {
      unsubscribe()
      this.currentIndex = this.currentIndex + direction
      this.weekDays = this.getWeekDays(this.currentIndex)
      this.getData()
    },
    getData () {
      const q = query(collection(db, 'Task'), where('weekNumber', '==', this.selectedWeekNumber))
      unsubscribe = onSnapshot(q, (querySnapshot) => {
        this.weekDays.forEach((day) => {
          day.tasks = []
        })
        querySnapshot.forEach((doc) => {
          // this.tasks.push(doc.data())
          const i = this.weekDays.findIndex(day => day.date === doc.data().date)
          if (i === -1) {
            return
          }
          this.weekDays[i].tasks.push({
            id: doc.id,
            ...doc.data()
          })
        })
        // console.log(this.weekDays)
        // console.log(this.tasks)
      })
    },
    selectDay (date) {
      // console.log(date)
      this.selectedDate = date
    },
    selectUser (id) {
      this.selectedUser = id
    },
    getWeekDays (index) {
      const currentWeekStart = dayjs().startOf('week').format('YYYY-MM-DD')
      const date = dayjs(currentWeekStart).add(index, 'week').format('YYYY-MM-DD')
      this.weekStart = dayjs(date).startOf('week').format('MMM D')
      this.weekEnd = dayjs(date).endOf('week').format('MMM D')
      return [...Array(7)].map((day, i) => {
        return {
          day: dayjs(date).add(i, 'day').format('D'),
          date: dayjs(date).add(i, 'day').format('YYYY-MM-DD'),
          dayName: dayjs(date).add(i, 'day').format('dddd'),
          weekNumber: dayjs('2022-02-02').add(i, 'day').week(),
          tasks: []
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.content-container {
  width: 2100px;
  box-shadow: 0 11px 7px rgba(0,0,0,0.015);
}

.dates {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 300px;
  justify-content: center;
}

span {
  display: block;
}

.active-user {
  box-shadow: 0 0 0 4px rgb(156, 163, 175);
  border: 2px white solid;
}
</style>
